<template>
  <div>
    <div v-if="style === 'swipe'" style="margin-left:5px;">
      <swiper
          ref="mySwiper"
          :direction="'horizontal'"
          :pagination="{ clickable: true }"
          :options="swiperOptionsListing"
          style="overflow: hidden"
          :style="`max-width: 600px; width: 100%;height:300px;`"
      >
        <swiper-slide
            v-for="item in list.filter(x => x.active)"
            :key="item.id"
            :style="`max-width: 600px; width: ${item.styles.width}%;`"
        >
          <div
              v-if="item.styles.show_name === 'top'"
              :style="`margin-bottom:10px;height:${
            item.styles.lining_height
          }px;width:${item.styles.lining_width}%;border-radius: ${
            globalSettings.radius
          }px;padding:${item.styles.lining_padding}px;background-color:${
            item.styles.main_menu_lining
          };display:flex;align-items:center;justify-content:center;flex-direction:${
            item.styles.show_icon_up ? 'column' : 'row'
          };`"
          >
            <img
                alt="image"
                v-if="item.styles.show_icon && item.styles.disable_icon_color"
                :src="item.icon[landing.current_lang]"
                class="canvas-menu__icon"
                :style="`height:${item.styles.icon_size}px;width:${
              item.styles.icon_size
            }px;min-height:${item.styles.icon_size}px;min-width:${
              item.styles.icon_size
            }px;margin-right:${!item.styles.show_icon_up ? '15px' : '0'}`"
            />
            <div
                v-if="item.styles.show_icon && !item.styles.disable_icon_color"
                class="canvas-menu__icon"
                :style="`margin-right:${
              !item.styles.show_icon_up ? '15px' : '0'
            };height:${item.styles.icon_size}px;width:${
              item.styles.icon_size
            }px;min-height:${item.styles.icon_size}px;min-width:${
              item.styles.icon_size
            }px;background-color: ${item.styles.icon_color};mask: url(${
              item.icon[landing.current_lang]
            }) no-repeat center / contain;-webkit-mask: url(${
              item.icon[landing.current_lang]
            }) no-repeat center / contain;`"
            ></div>

            <div style="text-align: center">
              {{ item.text["en"] }}
            </div>
          </div>
          <div
              class="canvas-menu__link"
              :style="`position:relative;z-index:1;height:${
            item.styles.height
          }px;color: ${item.styles.color};
                      border-radius: ${globalSettings.radius}px;box-shadow:${
            item.styles.background_submenu_preview
              ? 'none'
              : '0 ' +
                globalSettings.shadow_size +
                'px ' +
                '6px ' +
                globalSettings.shadow_color
          };background-color:${
            !item.styles.background_submenu_preview
              ? item.styles.background_main_color
              : 'transparent'
          };text-align:unset;
                    background-image: url('${item.styles.background}');
                    justify-content:${item.styles.position.split(' ')[0]};
      align-items:${item.styles.position.split(' ')[1]};
      background-repeat: no-repeat;
      background-size: cover;
      background-position:center;`"
          >
            <div
                v-if="item.styles.show_name === 'inside'"
                :style="`height:${item.styles.lining_height}px;width:${
              item.styles.lining_width
            }%;padding:${
              item.styles.lining_padding
            }px;background-color:${
              item.styles.main_menu_lining
            };display:flex;align-items:center;justify-content:center;flex-direction:${
              item.styles.show_icon_up ? 'column' : 'row'
            };`+checkRadius(item)"
            >
              <img
                  alt="image"
                  v-if="item.styles.show_icon && item.styles.disable_icon_color"
                  :src="item.icon[landing.current_lang]"
                  class="canvas-menu__icon"
                  :style="`height:${item.styles.icon_size}px;width:${
              item.styles.icon_size
            }px;min-height:${item.styles.icon_size}px;min-width:${
                item.styles.icon_size
              }px;margin-right:${!item.styles.show_icon_up ? '15px' : '0'}`"
              />
              <div
                  v-if="item.styles.show_icon && !item.styles.disable_icon_color"
                  class="canvas-menu__icon"
                  :style="`margin-right:${
                !item.styles.show_icon_up ? '15px' : '0'
              };height:${item.styles.icon_size}px;width:${
              item.styles.icon_size
            }px;min-height:${item.styles.icon_size}px;min-width:${
                item.styles.icon_size
              }px;background-color: ${item.styles.icon_color};mask: url(${
                item.icon[landing.current_lang]
              }) no-repeat center / contain;-webkit-mask: url(${
                item.icon[landing.current_lang]
              }) no-repeat center / contain;`"
              ></div>

              <div style="text-align: center">
                {{ item.text["en"] }}
              </div>
            </div>
          </div>
          <div
              v-if="item.styles.show_name === 'bottom'"
              :style="`margin-top:10px;height:${item.styles.lining_height}px;width:${
            item.styles.lining_width
          }%;margin-top:10px;border-radius: ${globalSettings.radius}px;padding:${
            item.styles.lining_padding
          }px;background-color:${
            item.styles.main_menu_lining
          };display:flex;align-items:center;justify-content:center;flex-direction:${
            item.styles.show_icon_up ? 'column' : 'row'
          };`"
          >
            <img
                alt="image"
                v-if="item.styles.show_icon && item.styles.disable_icon_color"
                :src="item.icon[landing.current_lang]"
                class="canvas-menu__icon"
                :style="`height:${item.styles.icon_size}px;width:${
              item.styles.icon_size
            }px;min-height:${item.styles.icon_size}px;min-width:${
              item.styles.icon_size
            }px;margin-right:${!item.styles.show_icon_up ? '15px' : '0'}`"
            />
            <div
                v-if="item.styles.show_icon && !item.styles.disable_icon_color"
                class="canvas-menu__icon"
                :style="`margin-right:${
              !item.styles.show_icon_up ? '15px' : '0'
            };height:${item.styles.icon_size}px;width:${
              item.styles.icon_size
            }px;min-height:${item.styles.icon_size}px;min-width:${
              item.styles.icon_size
            }px;background-color: ${item.styles.icon_color};mask: url(${
              item.icon[landing.current_lang]
            }) no-repeat center / contain;-webkit-mask: url(${
              item.icon[landing.current_lang]
            }) no-repeat center / contain;`"
            ></div>

            <div style="text-align: center">
              {{ item.text["en"] }}
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <div v-else class="canvas-menu" style="flex-direction:row;">
      <div
        class="canvas-menu__item canvas-menu-custom"
        v-for="item in list.filter(x => x.active)"
        :key="item.id"
        :style="`overflow:${
          item.styles.background_submenu_preview ? 'hidden' : 'unset'
        };margin-bottom:${+globalSettings.shadow_size + 10}px;color: ${
          item.styles.color
        };font-family:'${item.styles.fontFamily}',Inter,sans-serif;font-style:${
          item.styles.fontStyle
        };font-weight:${item.styles.fontWeight};font-size:${
          item.styles.fontSize
        }px;width:calc(${
          item.styles.width
        }% - 10px);margin-right: 10px;position:relative;`"
      >
        <div
          :class="`menu_${item.id}`"
          :style="`width:${360 * (item.styles.width / 100) - 5}px;height:${
            item.styles.height
          }px;overflow:hidden;z-index:0;position:absolute;border-radius: ${
            globalSettings.radius
          }px;`"
          v-if="item.styles.background_submenu_preview"
        ></div>
        <div
          v-if="item.styles.show_name === 'top'"
          :style="`margin-bottom:10px;height:${
            item.styles.lining_height
          }px;width:${item.styles.lining_width}%;border-radius: ${
            globalSettings.radius
          }px;padding:${item.styles.lining_padding}px;background-color:${
            item.styles.main_menu_lining
          };display:flex;align-items:center;justify-content:center;flex-direction:${
            item.styles.show_icon_up ? 'column' : 'row'
          };`"
        >
          <img
            alt="image"
            v-if="item.styles.show_icon && item.styles.disable_icon_color"
            :src="item.icon[landing.current_lang]"
            class="canvas-menu__icon"
            :style="`height:${item.styles.icon_size}px;width:${
              item.styles.icon_size
            }px;min-height:${item.styles.icon_size}px;min-width:${
              item.styles.icon_size
            }px;margin-right:${!item.styles.show_icon_up ? '15px' : '0'}`"
          />
          <div
            v-if="item.styles.show_icon && !item.styles.disable_icon_color"
            class="canvas-menu__icon"
            :style="`margin-right:${
              !item.styles.show_icon_up ? '15px' : '0'
            };height:${item.styles.icon_size}px;width:${
              item.styles.icon_size
            }px;min-height:${item.styles.icon_size}px;min-width:${
              item.styles.icon_size
            }px;background-color: ${item.styles.icon_color};mask: url(${
              item.icon[landing.current_lang]
            }) no-repeat center / contain;-webkit-mask: url(${
              item.icon[landing.current_lang]
            }) no-repeat center / contain;`"
          ></div>

          <div style="text-align: center">
            {{ item.text["en"] }}
          </div>
        </div>
        <div
          class="canvas-menu__link"
          :style="`position:relative;z-index:1;height:${
            item.styles.height
          }px;color: ${item.styles.color};
                      border-radius: ${globalSettings.radius}px;box-shadow:${
            item.styles.background_submenu_preview
              ? 'none'
              : '0 ' +
                globalSettings.shadow_size +
                'px ' +
                '6px ' +
                globalSettings.shadow_color
          };background-color:${
            !item.styles.background_submenu_preview
              ? item.styles.background_main_color
              : 'transparent'
          };text-align:unset;
                    background-image: url('${item.styles.background}');
                    justify-content:${item.styles.position.split(' ')[0]};
      align-items:${item.styles.position.split(' ')[1]};
      background-repeat: no-repeat;
      background-size: cover;
      background-position:center;`"
        >
          <div
            v-if="item.styles.show_name === 'inside'"
            :style="`height:${item.styles.lining_height}px;width:${
              item.styles.lining_width
            }%;padding:${
              item.styles.lining_padding
            }px;background-color:${
              item.styles.main_menu_lining
            };display:flex;align-items:center;justify-content:center;flex-direction:${
              item.styles.show_icon_up ? 'column' : 'row'
            };`+checkRadius(item)"
          >
            <img
              alt="image"
              v-if="item.styles.show_icon && item.styles.disable_icon_color"
              :src="item.icon[landing.current_lang]"
              class="canvas-menu__icon"
              :style="`height:${item.styles.icon_size}px;width:${
              item.styles.icon_size
            }px;min-height:${item.styles.icon_size}px;min-width:${
                item.styles.icon_size
              }px;margin-right:${!item.styles.show_icon_up ? '15px' : '0'}`"
            />
            <div
              v-if="item.styles.show_icon && !item.styles.disable_icon_color"
              class="canvas-menu__icon"
              :style="`margin-right:${
                !item.styles.show_icon_up ? '15px' : '0'
              };height:${item.styles.icon_size}px;width:${
              item.styles.icon_size
            }px;min-height:${item.styles.icon_size}px;min-width:${
                item.styles.icon_size
              }px;background-color: ${item.styles.icon_color};mask: url(${
                item.icon[landing.current_lang]
              }) no-repeat center / contain;-webkit-mask: url(${
                item.icon[landing.current_lang]
              }) no-repeat center / contain;`"
            ></div>

            <div style="text-align: center">
              {{ item.text["en"] }}
            </div>
          </div>
        </div>
        <div
          v-if="item.styles.show_name === 'bottom'"
          :style="`margin-top:10px;height:${item.styles.lining_height}px;width:${
            item.styles.lining_width
          }%;margin-top:10px;border-radius: ${globalSettings.radius}px;padding:${
            item.styles.lining_padding
          }px;background-color:${
            item.styles.main_menu_lining
          };display:flex;align-items:center;justify-content:center;flex-direction:${
            item.styles.show_icon_up ? 'column' : 'row'
          };`"
        >
          <img
            alt="image"
            v-if="item.styles.show_icon && item.styles.disable_icon_color"
            :src="item.icon[landing.current_lang]"
            class="canvas-menu__icon"
            :style="`height:${item.styles.icon_size}px;width:${
              item.styles.icon_size
            }px;min-height:${item.styles.icon_size}px;min-width:${
              item.styles.icon_size
            }px;margin-right:${!item.styles.show_icon_up ? '15px' : '0'}`"
          />
          <div
            v-if="item.styles.show_icon && !item.styles.disable_icon_color"
            class="canvas-menu__icon"
            :style="`margin-right:${
              !item.styles.show_icon_up ? '15px' : '0'
            };height:${item.styles.icon_size}px;width:${
              item.styles.icon_size
            }px;min-height:${item.styles.icon_size}px;min-width:${
              item.styles.icon_size
            }px;background-color: ${item.styles.icon_color};mask: url(${
              item.icon[landing.current_lang]
            }) no-repeat center / contain;-webkit-mask: url(${
              item.icon[landing.current_lang]
            }) no-repeat center / contain;`"
          ></div>

          <div style="text-align: center">
            {{ item.text["en"] }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import "swiper/swiper-bundle.css";
import "swiper/modules/pagination/pagination.min.css";
import SwiperCore, { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
SwiperCore.use([Pagination]);

export default {
  name: "MainPageStylesPreview",
  data(){
    return{
      swiperOptionsListing: {
        grabCursor: true,
        centeredSlides: false,
        slidesPerView:"auto",
        spaceBetween:15
      },
    }
  },
  computed: {
    ...mapState("mainPageStyles", ["globalSettings", "style", "list"]),
    ...mapState(['landing']),
  },
  components:{ Swiper, SwiperSlide },
  methods:{
    checkRadius(item){
      if(+item.styles.lining_width === 100){
        if(item.styles.position === 'flex-start flex-start' || item.styles.position === 'flex-end flex-start' || item.styles.position === 'center flex-start'){
          return `border-top-right-radius:${this.globalSettings.radius}px;border-top-left-radius:${this.globalSettings.radius}px;`
        }
        if(item.styles.position === 'flex-start flex-end' || item.styles.position === 'flex-end flex-end' || item.styles.position === 'center flex-end'){
          return `border-bottom-right-radius:${this.globalSettings.radius}px;border-bottom-left-radius:${this.globalSettings.radius}px;`
        }
      }else{
        return `border-radius:${this.globalSettings.radius}px;`
      }
    }
  }
};
</script>

<style scoped lang="scss"></style>
